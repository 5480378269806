import { useEffect, useState } from "react";

// components
import { MenuUser } from "../../components/MenuUser/MenuUser.js";
import { CardHolding } from "../../components/CardHolding/CardHolding.js";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import SeasonHolding from "../../components/SeasonHolding/SeasonHolding.js";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage.js";
import { fetchData } from "../../services/apiService";

// resources
import logo_adidas_white from "../../assets/img/svg/adidas_white.svg";

// styles
import "../../assets/css/pages/holding.css";

const Holding = () => {
  const
    [myData, setMyData] = useState(null),
    [newsData, setNewsData] = useState([]),
    [seasonId, setSeasonId] = useState(null),
    [errorMessage, setErrorMessage] = useState(""),
    [activeButton, setActiveButton] = useState(null)
  ;

  const url_last_update = "/api/list/last-updates";

  const renderCardSections = (sections) => {
    return sections.map(
      ({ module, imgSrc, altText, cards, segments, cardType }, index) => (
        <div key={index} className={`holding_section holding_section_${index}`}>
          <h1 className="title_holding2">{module}</h1>
          <div className="content">
            <picture className="img_left">
              <img src={imgSrc} alt={altText} className="img" />
            </picture>

            <div className="cards_container">
              {segments.map((segment, idx) => (
                <CardHolding key={idx} cardType={cardType} segments={segment} />
              ))}
            </div>
          </div>
        </div>
      )
    );
  };

  const ContainerCards = () => {
    if (myData !== null) {
      const sections = myData.slice(0, 2);

      return (
        <div className="container_cards">
          {renderCardSections(sections)}
          <SideBySide />
        </div>
      );
    }

    return null;
  };

  const SideBySide = () => {
    if (myData !== null) {
      const sections = myData.slice(2, 4);

      return <div className="side_by_side">{renderCardSections(sections)}</div>;
    }

    return null;
  };

  const fetchDataHolding = async (seasonId) => {
    const url_service = `/api/home/${seasonId}`;

    try {
      const response = await fetchData(url_service);

      if (response.success) {
        const typeCard = ["", "type_2", "type_3", "type_3"];

        let result = [];

        response.data.forEach((elem, index) => {
          result.push({
            module: elem.module,
            imgSrc: elem.img,
            altText: elem.module,
            cards: elem.segments.length,
            segments: elem.segments,
            cardType: typeCard[index],
          });
        });

        setMyData(result);
        setErrorMessage("");
      } else {
        setErrorMessage(response.message);
        setMyData(null);
      }
    } catch(error) {
      console.error("Error fetching Holding:", error)
    }
  };

  const fetchDataNews = async () => {
    try {
      const response = await fetchData(url_last_update);
      setNewsData(response.data);
    } catch(error) {
      console.error("Error fetching Data News:", error)
    }
  };

  useEffect(() => {
    if (seasonId) {
      fetchDataHolding(seasonId);
    }
  }, [seasonId]);

  useEffect(() => {
    fetchDataNews();
  }, []);

  return (
    <div className="container_holding layout_3_l">
      <NavigationBar className="a3l_1" styleType={"open"} news={newsData} />
      <MenuUser />

      <div className="container_page a3l_2">
        <picture className="single_logo_adidas">
          <img src={logo_adidas_white} alt="adidas" className="img" />
        </picture>

        <h1 className="title_holding">OMNI LAM SEASONAL ACTIVATION PLAN</h1>
        <h2 className="subtitle_holding no_visible">OMNI LAM SEASONAL ACTIVATION PLAN</h2>
        <p className="desc_holding">
          Bienvenido al Omni LAM Seasonal Activation Plan, selecciona el modelo de segmentación
          de tu preferencia y temporada
        </p>

        <div className="container_general">
          <SeasonHolding
            onSeasonChange={setSeasonId}
            setActiveButton={setActiveButton}
            activeButton={activeButton}
          />
          {errorMessage ? (
            <ErrorMessage
              onSeasonChange={setSeasonId}
              setActiveButton={setActiveButton}
            />
          ) : (
            <ContainerCards />
          )}
        </div>
      </div>
    </div>
  );
};

export default Holding;
